import React, { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import Button from '@shared/components/button';

import useActions from '@shared/hooks/useActions';
import { actions } from '@store/companies';
import { Field, Fields } from '@shared/views/form-dialog';
import ToneMappingTextField from '@pages/studio/ToneMappingTextField';
import RangeSlider from '@pages/scenes/child-details/LightDetails/RangeSlider';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '2rem',
  },
  content: {
    width: '400px',
  },
  actions: {
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
}));

export const ActionType = {
  CREATE: 'Create',
  UPDATE: 'Update',
};

const CompanyModal = ({ open, onClose, type, company }) => {
  const { createCompany, updateCompany } = useActions(actions);
  const { control, formState: { errors: fieldsErrors }, handleSubmit, reset } = useForm();

  const classes = useStyles();

  useEffect(() => {
    reset({
      name: company?.name || '',
      toneMapping: company?.toneMapping || 'CustomToneMapping',
      toneMappingExposure: company?.toneMappingExposure || 1,
    });
  }, [company, reset]);

  const submit = (data) => {
    onClose();

    if (type === ActionType.CREATE) createCompany(data);
    if (type === ActionType.UPDATE) updateCompany({ id: company.id, company: data });
  };

  const onSubmit = handleSubmit(submit);

  return (
    <Dialog open={ open } onClose={ onClose }>
      <DialogTitle className={ classes.title } disableTypography>{type} Company</DialogTitle>
      <DialogContent className={ classes.content }>
        <form noValidate onSubmit={ onSubmit }>
          <Fields>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="name"
                  control={ control }
                  defaultValue={ company?.name || '' }
                  rules={ { required: 'Required' } }
                  render={ ({ field }) => (
                    <TextField
                      { ...field }
                      id="name"
                      helperText={ fieldsErrors.name?.message ?? null }
                      variant="outlined"
                      label="Name"
                      required
                      error={ !!fieldsErrors.name }
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="toneMapping"
                  control={ control }
                  defaultValue="CustomToneMapping"
                  rules={ {
                    required: 'Required',
                  } }
                  render={ ({ field }) => (
                    <ToneMappingTextField
                      { ...field }
                      label="Tone Mapping"
                      required
                    />
                  ) }
                />
              </FormControl>
            </Field>
            <Field>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="toneMappingExposure"
                  defaultValue="1"
                  render={ ({ field }) => (
                    <RangeSlider
                      label="Tone Mapping Exposure"
                      min={ 0 }
                      max={ 10 }
                      displayValues={ false }
                      displayThumbValue
                      debounce={ 100 }
                      { ...field }
                      onChange={ ({ value }) => {
                        field.onChange(value);
                      } }
                    />
                  ) }
                  control={ control }
                />
              </FormControl>
            </Field>
          </Fields>
        </form>
      </DialogContent>
      <DialogActions className={ classes.actions }>
        <Button color="secondary" variant="outlined" onClick={ onClose }>Cancel</Button>
        <Button onClick={ onSubmit }>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyModal;
