export const BaseFieldset = {
  Name: 'name',
  Type: 'type',
  Preset: 'preset',
  Hidden: 'hidden',
  Orientation: 'orientation',
  Selected: 'selected',
  Group: 'group',
  IntensityByType: 'intensityByType',
  ColorByType: 'colorByType',
  OrientationByType: 'orientationByType',
  PositionByType: 'positionByType',
  DistanceByType: 'distanceByType',
  IsShadowByType: 'isShadowByType',
};

export const SpotLightFieldset = {
  Color: 'color',
  Intensity: 'intensity',
  Distance: 'distance',
  Angle: 'angle',
  Focus: 'focus',
  Penumbra: 'penumbra',
  Decay: 'decay',
  VolumeOpacity: 'volumeOpacity',
  // Power: 'power', changes intensity

  Position: 'position',
  Target: 'target',
  SelectSpotCenter: 'selectSpotCenter',
  IsShadow: 'isShadow',
};

export const DirectionalLightFieldset = {
  Color: 'color',
  Intensity: 'intensity',
  // Power: 'power', changes intensity

  Position: 'position',
  IsShadow: 'isShadow',
};

export const PointLightFieldset = {
  Color: 'color',
  Intensity: 'intensity',
  Distance: 'distance',
  Decay: 'decay',
  // Power: 'power', changes intensity

  Position: 'position',
  IsShadow: 'isShadow',
};
