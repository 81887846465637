import { SpotLightFieldset } from './fieldset';
import ColorOption from './lightOptions/ColorOption';
import IntensityOption from './lightOptions/IntensityOption';

import ShadowController from './shadow/Shadow';
import PositionOption from '@pages/scenes/child-details/LightDetails/lightOptions/PositionOption';

const DirectionalLightOptions = ({
  options,
  onChange,
}) => (
  <>
    <PositionOption
      name={ SpotLightFieldset.Position }
      value={ options[SpotLightFieldset.Position] }
      onChange={ onChange }
    />
    <PositionOption
      name={ SpotLightFieldset.Target }
      value={ options[SpotLightFieldset.Target] }
      label="Target"
      onChange={ onChange }
    />
    <IntensityOption
      name={ SpotLightFieldset.Intensity }
      value={ options[SpotLightFieldset.Intensity] }
      onChange={ onChange }
    />
    <ColorOption
      name={ SpotLightFieldset.Color }
      value={ options[SpotLightFieldset.Color] }
      onChange={ onChange }
    />
    <ShadowController name={ SpotLightFieldset.IsShadow } value={ options[SpotLightFieldset.IsShadow] } onChange={ onChange } />
  </>
);

export default DirectionalLightOptions;
