import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import filterData from '@app/filter/filterData';
import InteriorListGrid from './grid';
import { selectSortingId } from '@store/app/selectors';
import sortData from '@app/sort';
import { interiorsSelector } from '@store/interiors/selectors';
import { selectAttributesFilterValue } from '@store/attribute-definitions/selectors';
import FilterContainer from '@shared/components/attributes-filter/FilterContainer';
import filterByAttributes from '@shared/components/attributes-filter';

const selectSearchString = (state) => state.app.searchString;

const InteriorList = () => {
  const interiors = useSelector(interiorsSelector);
  const sortingId = useSelector(selectSortingId);
  const searchString = useSelector(selectSearchString);
  const attributesFilterValue = useSelector(selectAttributesFilterValue);

  const items = useMemo(() => {
    const filteredItems = filterData(interiors, searchString);
    const filteredByAttributesItems = filterByAttributes(filteredItems, attributesFilterValue);
    return sortData(filteredByAttributesItems, sortingId);
  }, [interiors, searchString, sortingId, attributesFilterValue]);

  return (
    <FilterContainer>
      <InteriorListGrid data={ items } />
    </FilterContainer>
  );
};

export default InteriorList;
