import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import filterData from '@app/filter/filterData';
import StudioListGrid from './grid';
import { selectSortingId } from '@store/app/selectors';
import sortData from '@app/sort';

const selectStudios = (state) => state.studios.studios;

const selectSearchString = (state) => state.app.searchString;

const StudioList = () => {
  const data = useSelector(selectStudios);
  const sortingId = useSelector(selectSortingId);
  const searchString = useSelector(selectSearchString);
  const items = useMemo(() => {
    const filteredItems = filterData(data.items, searchString);
    return sortData(filteredItems, sortingId);
  }, [data.items, searchString, sortingId]);

  return (
    <StudioListGrid data={ items } />
  );
};

export default StudioList;
