import { all, call, put, takeEvery } from 'redux-saga/effects';
import { authApi, usersApi, companiesApi } from '@api';
import http from '@shared/services/http';
import { extractErrorMessage } from '@shared/utils';

import { actions } from '.';

const { initApp, login, logout, setLoading, setUser, setError, setCompany } = actions;

export function* initAppSaga() {
  try {
    const { token } = yield call(authApi.refreshToken);
    http.setToken(token);

    const user = yield call(usersApi.getCurrent);
    yield put(setUser(user));
  } catch {
    // ignore - we'll be redirected to login
  } finally {
    yield put(setLoading(false));
  }
}

export function* loginSaga(action) {
  const credentials = action.payload;
  try {
    const { user, token } = yield call(authApi.login, credentials);
    http.setToken(token);
    yield put(setUser(user));
  } catch (error) {
    yield put(setError(extractErrorMessage(error)));
  }
}

export function* logoutSaga() {
  yield put(setUser(null));
  http.removeToken();
}

export default function* appSaga() {
  yield all([
    takeEvery(initApp, initAppSaga),
    takeEvery(login, loginSaga),
    takeEvery(logout, logoutSaga),
  ]);
}
