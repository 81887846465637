export const Fieldset = {
  Id: 'id',
  Name: 'name',
  Url: 'url',
  Image: 'image',
  Textures: {
    map: { key: 'textures.map', label: 'Color Map', type: 'COLOR_MAP' },
    normalMap: { key: 'textures.normalMap', label: 'Normal Map', type: 'NORMAL_MAP' },
    roughnessMap: { key: 'textures.roughnessMap', label: 'Roughness Map', type: 'ROUGH_MAP' },
    specularColorMap: { key: 'textures.specularColorMap', label: 'Specular Map', type: 'SPEC_MAP' },
    alphaMap: { key: 'textures.alphaMap', label: 'Alpha Map', type: 'ALPHA_MAP' },
    metalnessMap: { key: 'textures.metalnessMap', label: 'Metalness Map', type: 'METALNESS_MAP' },
    displacementMap: { key: 'textures.displacementMap', label: 'Displacement Map', type: 'DISPLACEMENT_MAP' },
    aoMap: { key: 'textures.aoMap', label: 'Ambient Occlusion map', type: 'AO_MAP' },
    sheenColorMap: { key: 'textures.sheenColorMap', label: 'Sheen Color Map', type: 'SHEEN_COLOR_MAP' },
    sheenRoughnessMap: { key: 'textures.sheenRoughnessMap', label: 'Sheen Roughness Map', type: 'SHEEN_ROUGHNESS_MAP' },
    clearcoatMap: { key: 'textures.clearcoatMap', label: 'Clearcoat Map', type: 'CLEARCOAT_MAP' },
    clearcoatNormalMap: { key: 'textures.clearcoatNormalMap', label: 'Clearcoat Normal Map', type: 'CLEARCOAT_NORMAL_MAP' },
    clearcoatRoughnessMap: { key: 'textures.clearcoatRoughnessMap', label: 'Clearcoat Roughness Map', type: 'CLEARCOAT_ROUGHNESS_MAP' },
  },
  Configuration: {
    // ior: { key: 'configuration.ior', input: 'slider', min: 0, max: 2.333, default: 1.5, label: 'Index-of-refraction' },
    roughness: { key: 'configuration.roughness', input: 'slider', min: 0, max: 1, default: 1, label: 'Roughness' },
    metalness: { key: 'configuration.metalness', input: 'slider', min: 0, max: 1, default: 1, label: 'Metalness' },
    reflectivity: { key: 'configuration.reflectivity', input: 'slider', min: 0, max: 1, default: 1, label: 'Reflectivity' },
    specularIntensity: { key: 'configuration.specularIntensity', input: 'slider', min: 0, max: 1, default: 1, label: 'Specular Intensity' },
    aoMapIntensity: { key: 'configuration.aoMapIntensity', input: 'slider', min: 0, max: 1, default: 1, label: 'Ambient Occlusion Intensity' },
    displacementScale: {
      key: 'configuration.displacementScale', input: 'slider', min: 0, max: 1, default: 0, step: 0.001, label: 'Displacement Scale',
    },
    displacementBias: {
      key: 'configuration.displacementBias', input: 'slider', min: -1, max: 1, default: 0, step: 0.001, label: 'Displacement Bias',
    },
    clearcoat: { key: 'configuration.clearcoat', input: 'slider', min: 0, max: 1, default: 0, label: 'Clearcoat' },
    clearcoatRoughness: { key: 'configuration.clearcoatRoughness', input: 'slider', min: 0, max: 1, default: 0, label: 'Clearcoat Roughness' },
    sheen: { key: 'configuration.sheen', input: 'slider', min: 0, max: 1, default: 0, label: 'Sheen' },
    sheenColor: { key: 'configuration.sheenColor', input: 'color', default: '#ffffff', label: 'Sheen Color' },
    sheenRoughness: { key: 'configuration.sheenRoughness', input: 'slider', min: 0, max: 1, default: 1, label: 'Sheen Roughness' },
  },
  Attributes: 'attributes',
  TextureSize: 'textureSize',
  TranslatedFields: 'translatedFields',
};

export const DEFAULT_VALUES = {
  [Fieldset.Name]: '',
  [Fieldset.Url]: '',
  [Fieldset.Width]: '',
  [Fieldset.Height]: '',
  [Fieldset.Image]: {
    blob: '',
  },
  [Fieldset.Attributes]: [],
  [Fieldset.TextureSize]: 0,
  [Fieldset.TranslatedFields]: {},
};


export const fillWithMaterialValues = (material) => ({
  ...material,
  [Fieldset.TextureSize]: 0,
});

export const InputLabelProps = {
  shrink: true,
};
